import React from "react"
import { FormattedMessage } from "gatsby-plugin-intl"
import "./FormField.scss"

const FormLabel = ({ txt, name }) => {
  const Regular = (
    <label htmlFor={name} className="material">
      {txt}
    </label>
  )

  const Required = (
    <label htmlFor={name} className="material material-required">
      {txt.split("*")[1]}
    </label>
  )

  return txt.includes("*") ? Required : Regular
}

const FormField = ({
  name,
  id,
  value,
  onChange,
  isDisabled,
  type = "text",
  hasError = false,
  errorTextId,
}) => {
  let className = "form-group group"

  if (isDisabled) {
    className += " group--disabled"
  }

  if (hasError) {
    className += " group--invalid"
  }

  return (
    <>
      <div className={className}>
        <input
          className="material"
          type={type}
          name={name}
          onChange={onChange}
          value={value}
          disabled={isDisabled}
          required="required"
        />
        <span className="highlight"></span>
        <span className="bar"></span>
        <FormattedMessage id={id}>
          {txt => <FormLabel txt={txt} name={name} />}
        </FormattedMessage>
      </div>
      {hasError && (
        <div className="form-group">
          <FormattedMessage id={errorTextId}>
            {txt => <p className="text-danger small">{txt}</p>}
          </FormattedMessage>
        </div>
      )}
    </>
  )
}

export default FormField

import React from "react"
import {
  FormattedMessage,
  IntlContextConsumer,
  navigate,
} from "gatsby-plugin-intl"
import { Layout, SEO } from "../components/Layout"
import { FormField } from "../components/FormField"
import {
  createContact,
  emailIsValid,
  getGDPRLink,
  getTermsLink,
} from "../utils"

export default class Office extends React.Component {
  state = {
    type: "office",
    company: "",
    email: "",
    phone: "",
    city: "",
    termsChecked: false,
    emailError: false,
    termsError: false,
    nameError: false,
  }

  handleChange = event => {
    const { name, value, type, checked } = event.target
    const val = type === "checkbox" ? checked : value

    this.setState({
      [name]: val,
    })
  }

  validateInputs = currentLocale => {
    const { company, email, termsChecked } = this.state

    this.setState({ emailError: false, nameError: false, termsError: false })

    if (!company) {
      this.setState({ nameError: true })
    }

    if ((email && !emailIsValid(email)) || !email) {
      this.setState({ emailError: true })
    }

    if (!termsChecked) {
      this.setState({ termsError: true })
    }

    if (company && email && emailIsValid(email) && termsChecked) {
      this.handleSubmit(currentLocale)
    }
  }

  handleSubmit = async currentLocale => {
    try {
      await createContact({ ...this.state, lang: currentLocale })
      navigate("/success/")
    } catch (error) {
      this.setState({ emailError: true })
    }
  }

  render() {
    const {
      company,
      email,
      phone,
      city,
      termsChecked,
      emailError,
      nameError,
      termsError,
    } = this.state

    const opengraph = {
      ogUrl: "https://traduc.ro/office/",
      ogTitle: "Înscrie-te ca birou de traduceri!",
      ogImage: "https://traduc.ro/images/traduc_ro_office.jpg",
      ogImageWidth: "1080",
      ogImageHeight: "617",
    }

    return (
      <IntlContextConsumer>
        {({ language: currentLocale }) => (
          <Layout>
            <SEO title="Office" opengraph={opengraph} />
            <section className="apply-page">
              <div className="container">
                <div className="row justify-content-between">
                  <div className="col-12 col-sm-11 col-md-7 col-lg-6 text-center text-sm-left text-white">
                    <FormattedMessage id="officePage.heading">
                      {txt => <h1 className="pt-3">{txt}</h1>}
                    </FormattedMessage>

                    <FormattedMessage id="common.noSpam">
                      {txt => <p className="py-2 py-sm-3 py-lg-4">{txt}</p>}
                    </FormattedMessage>
                  </div>
                  <div className="col-12 col-md-5 p-5 form-container">
                    <FormattedMessage id="officePage.heading">
                      {txt => (
                        <h5 className="text-center text-primary font-weight-bold pb-4">
                          {txt}
                        </h5>
                      )}
                    </FormattedMessage>
                    <form>
                      <FormField
                        onChange={this.handleChange}
                        value={company}
                        name="company"
                        id="officePage.company"
                        hasError={nameError}
                        errorTextId="common.requiredError"
                      />
                      <FormField
                        onChange={this.handleChange}
                        value={email}
                        name="email"
                        id="common.email"
                        hasError={emailError}
                        errorTextId="common.emailError"
                      />
                      <FormField
                        onChange={this.handleChange}
                        value={phone}
                        name="phone"
                        id="common.phone"
                      />
                      <FormField
                        onChange={this.handleChange}
                        value={city}
                        name="city"
                        id="officePage.city"
                      />
                      <div className="form-group form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="accept"
                          checked={termsChecked}
                          name="termsChecked"
                          onChange={this.handleChange}
                        />
                        <label
                          className="form-check-label text-primary font-weight-lighter"
                          htmlFor="accept"
                        >
                          <FormattedMessage id="common.checkboxPre" />{" "}
                          <FormattedMessage id="common.checkboxTerms">
                            {txt => (
                              <a
                                className="font-weight-normal text-underline text-primary"
                                target="_blank"
                                rel="noopener noreferrer"
                                href={getTermsLink(currentLocale)}
                              >
                                {txt}
                              </a>
                            )}
                          </FormattedMessage>{" "}
                          <FormattedMessage id="common.checkboxMid" />{" "}
                          <FormattedMessage id="common.checkboxData">
                            {txt => (
                              <a
                                className="font-weight-normal text-underline text-primary"
                                target="_blank"
                                rel="noopener noreferrer"
                                href={getGDPRLink(currentLocale)}
                              >
                                {txt}
                              </a>
                            )}
                          </FormattedMessage>{" "}
                        </label>
                        {termsError && (
                          <FormattedMessage id="common.requiredError">
                            {txt => <p className="text-danger small">{txt}</p>}
                          </FormattedMessage>
                        )}
                      </div>
                      <div className="form-group pt-3 pt-sm-5 text-center text-sm-right">
                        <FormattedMessage id="common.apply">
                          {txt => (
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={() => this.validateInputs(currentLocale)}
                            >
                              {txt}
                            </button>
                          )}
                        </FormattedMessage>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </section>
          </Layout>
        )}
      </IntlContextConsumer>
    )
  }
}
